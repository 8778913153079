import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../stylesheets/galleryGrid.scss";
import Pageheader from "../components/pageheader";



const AboutPage = ({ data }) => {

return(
    <Layout>
      <SEO title="Design" />
      <Pageheader title='about' image='image' body='body'/>
      <div className="container">
        <div className="row">
 
        </div>
      </div>
    </Layout>
)
  }



export default AboutPage;