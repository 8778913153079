//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../stylesheets/pageheader.scss"



function pageheader(info) {

  const headerTitle = info.title;
  const headerImage = info.image.fixed.src;
  const body = info.body.body;

  return (
    <div className="container-fluid pageheader "  style={{
      backgroundImage: 'url("'+headerImage+'")'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 d-flex align-items-center justify-content-center">
            <div>
            <h1>{headerTitle}</h1>
            <p>{body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


pageheader.propTypes = {
  headerTitle: PropTypes.string,
  headerImage: PropTypes.string,
  body: PropTypes.object,
}

pageheader.defaultProps = {
  headerTitle : '',
  headerImage: '',
}

export default pageheader;

